import * as React from 'react';
import { Link } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material';

import { paths } from 'src/routes/paths';

import { primary } from 'src/theme/palette';

import { useTabs } from './tabs.hook';

export interface TabsProps {}

interface LinkTabProps {
  label?: string;
  href?: string;
  selected?: boolean;
}

function LinkTab(props: LinkTabProps) {
  const { href, ...other } = props;
  return (
    <Tab
      component={Link}
      to={href ?? '#'}
      {...other}
    />
  );
}

export default function HeaderTabs(Props: TabsProps) {
  const { value, handleChange } = useTabs()

  const CustomTabLink = styled((LinkTab))(
    ({ theme }) => ({
      marginTop: 8,
      color: theme.palette.common.white,
      '&.MuiTab-root':{
        color: 'white',
        fontWeight: theme.typography.subtitle2,
        fontSize: theme.typography.subtitle2,
        lineHeight: theme.typography.subtitle2,
        fontFamily: theme.typography.fontFamily,
      },
    }),
  );

  const divStylesConfig = {
    height: '100%'
  }

  return (
    <div data-testid="tabs-render-test" style={divStylesConfig}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          role="navigation"
          sx={{height: '100%'}}
          TabIndicatorProps={{
            sx: {
              backgroundColor: primary.light, 
              height: 3
            },
          }}
        >
          <CustomTabLink label="Pedidos" href={paths.orders.root}/>
          <CustomTabLink label="Kanban" href="/" />
          <CustomTabLink label="Clientes" href="/" />
          <CustomTabLink label="Instaladores" href="/" />
          <CustomTabLink label="Ciclos" href="/" />
        </Tabs>
      </Box>
    </div>
  );
}
