import { Either } from 'src/utils/either';

import { httpResponseHandler } from 'src/http/http-response-handler';

import {
  HttpTypes,
  BadRequestError,
  ConnectionError,
  UnexpectedError,
  AccessDeniedError,
  InternalServerError,
  TooManyRequestsError,
} from '../../http';

export class ViaCepRepository {
  constructor(private api: HttpTypes.HttpClient) {}

  async getLocalByCep(
    cep?: string,
  ): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/ws/${cep}/json/`,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }
}
