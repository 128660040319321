import { paths } from 'src/routes/paths';

export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const API_URL = import.meta.env.VITE_API_URL;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const ASSETS_API_URL = import.meta.env.VITE_ASSETS_API_URL;


export const PATH_AFTER_LOGIN = paths.root;

export const PATH_TO_REFRESH_TOKENS = paths.auth.refreshTokens
