import moment from 'moment';

import { Comment } from 'src/types/comment';
import { Status } from 'src/types/order-details/status';
import { Activity } from 'src/types/order-details/activity';
import { Schedule } from 'src/types/order-details/schedule';
import { ChatMessageItemProps } from 'src/types/order-details/chat';

import { getRandomCPF, getRandomInt, getRandomItem, getRandomFloat } from './_orders';

export const popularNames = [
  'Miguel',
  'Arthur',
  'Heitor',
  'Helena',
  'Alice',
  'Laura',
  'Gabriel',
  'Bernardo',
  'Samuel',
  'Sophia',
  'Maria',
  'Carlos',
  'João',
  'Ana',
  'Lucas',
  'Mariana',
  'Rafael',
  'Fernanda',
  'Guilherme',
  'Juliana',
  'Felipe',
  'Roberto',
  'Bruno',
  'Beatriz',
  'Patrícia',
  'Rodrigo',
  'Eduardo',
  'Matheus',
  'Camila',
  'Ricardo',
];
export const popularSurnames = [
  'Silva',
  'Santos',
  'Oliveira',
  'Pereira',
  'Souza',
  'Rodrigues',
  'Ferreira',
  'Almeida',
  'Costa',
  'Gomes',
  'Martins',
];
export const cities = [
  'Porto Alegre',
  'Canoas',
  'Novo Hamburgo',
  'São Leopoldo',
  'Gravataí',
  'Viamão',
  'Alvorada',
  'Guaíba',
  'Sapucaia do Sul',
  'Esteio',
];
export const states = ['RS'];

export const services = [...Array(2)].map((_, index) => ({
  id: index + 1,
  modelo: 'Split Inverter',
  marca: 'Samsung',
  potencia: '9000',
  tipo_servico_id: getRandomInt(1, 12),
  tipo_item_id: 1,
  valor_unitario: 1000.00,
  valor_desconto: 0,
  valor_liquido: 1000.00,
  valor_repasse: 740.00,
  valor_repasse_agyx: 360.00,
  porcentagem_repasse: 0.75,
}));

function getRandomBoolean(): boolean {
  return Math.random() < 0.5;
}

export function getOrderDetailsDataMock() {
  const data = {
    name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf_cnpj: getRandomCPF(),
    email: 'teste@teste.com',
    phone: '(51) 91234-5678',
    billing_cep: '96760-000',
    billing_address: 'Rua Exemplo',
    billing_city: getRandomItem(cities),
    billing_state: getRandomItem(states),
    billing_district: 'Centro',
    billing_number: 1,
    billing_complement: 'Casa',
    installation_cep: '96760-000',
    installation_address: 'Rua Exemplo',
    installation_city: getRandomItem(cities),
    installation_state: getRandomItem(states),
    installation_district: 'Centro',
    installation_number: 1,
    installation_complement: 'Casa',
    order_items: services,
    isEqualToInstallation: getRandomBoolean(),
    defaultTransferPercent: 0.75,
    service_date: moment().date(),
    service_shift: getRandomItem(['Manhã', 'Tarde']),
    order_date: moment().date(),
    total_value: getRandomFloat(),
    installer_value: getRandomFloat(),
    agyx_value: getRandomFloat(),
    stateOptions: [
      "AC", // Acre
      "AL", // Alagoas
      "AP", // Amapá
      "AM", // Amazonas
      "BA", // Bahia
      "CE", // Ceará
      "DF", // Distrito Federal
      "ES", // Espírito Santo
      "GO", // Goiás
      "MA", // Maranhão
      "MT", // Mato Grosso
      "MS", // Mato Grosso do Sul
      "MG", // Minas Gerais
      "PA", // Pará
      "PB", // Paraíba
      "PR", // Paraná
      "PE", // Pernambuco
      "PI", // Piauí
      "RJ", // Rio de Janeiro
      "RN", // Rio Grande do Norte
      "RS", // Rio Grande do Sul
      "RO", // Rondônia
      "RR", // Roraima
      "SC", // Santa Catarina
      "SP", // São Paulo
      "SE", // Sergipe
      "TO"  // Tocantins
    ],
    orderStatus: {
      status: 'Em Andamento',
      date: new Date,
    },
  }

  return data;
}

export const _activities: Activity[] = [
  {
    id: 1,
    title: 'Entrada do pedido',
    user: {
      id: 1,
      name: 'João Silva',
    },
    date: moment().subtract(getRandomInt(1720, 4920), 'hours').toDate(),
  },
  {
    id: 2,
    title: 'Contato com cliente',
    user: {
      id: 2,
      name: 'Maria Santos',
    },
    date: moment().subtract(getRandomInt(500, 600), 'hours').toDate(),
  },
  {
    id: 3,
    title: 'Agendamento',
    user: {
      id: 3,
      name: 'José Almeida',
    },
    date: moment().subtract(getRandomInt(400, 450), 'hours').toDate(),
  },
  {
    id: 4,
    title: 'Aguardando match',
    user: {
      id: 4,
      name: 'Ana Oliveira',
    },
    date: moment().subtract(getRandomInt(350, 400), 'hours').toDate(),
  },
  {
    id: 5,
    title: 'Aguardando NF',
    user: {
      id: 5,
      name: 'Pedro Souza',
    },
    date: moment().subtract(getRandomInt(100, 200), 'hours').toDate(),
  },
  {
    id: 6,
    title: 'Concluído',
    user: {
      id: 6,
      name: 'Marta Pereira',
    },
    date: moment().subtract(getRandomInt(1, 24), 'hours').toDate(),
  },
];

export const _orderStatuses: Status[] = [
  {
    id: 1,
    title: 'Não Atendido',
    date: moment().subtract(getRandomInt(50, 60), 'days').toDate(),
  },
  {
    id: 2,
    title: 'Em Atendimento',
    date: moment().subtract(getRandomInt(38, 50), 'days').toDate(),
  },
  {
    id: 3,
    title: 'Aguardando Match',
    date: moment().subtract(getRandomInt(32, 38), 'days').toDate(),
  },
  {
    id: 4,
    title: 'Agendado',
    date: moment().subtract(getRandomInt(16, 32), 'days').toDate(),
  },
  {
    id: 5,
    title: 'Aguardando NF',
    date: moment().subtract(getRandomInt(12, 16), 'days').toDate(),
  },
  {
    id: 6,
    title: 'Concluído',
    date: moment().subtract(getRandomInt(10, 12), 'days').toDate(),
  },
  {
    id: 7,
    title: 'Pago',
    date: moment().subtract(getRandomInt(1, 10), 'days').toDate(),
  },
];

export const _schedules: Schedule[] = [...Array(5)].map((_, index) => ({
  id: (index + 1).toString(),
  date: moment().add(getRandomInt(1, 10), 'days').format('YYYY-MM-DD'),
  turn: getRandomItem(['Manhã', 'Tarde']),
  matchType: getRandomItem(['Amplo', 'Individual']),
  installer: {
    id: getRandomInt(1, 100).toString(),
    name: getRandomItem(popularNames),
  },
  status: 'Agendado',
}));

const mockUsers = [...Array(6)].map((_, index) => ({
  id: index.toString(),
  name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
  avatarUrl: '/static/images/avatars/avatar_1.jpg',
}));

const mockReplyComments = [...Array(3)].map((_, index) => ({
  id: index.toString(),
  userId: mockUsers[1].id,
  message: 'Concordo com o que você disse.',
  postedAt: new Date('2024-08-12T10:30:00'),
}));

export const _mockComments: Comment[] = [...Array(5)].map((_, index) => ({
  id: index.toString(),
  message: 'Este é um comentário de teste.',
  postedAt: new Date('2024-08-12T10:00:00'),
  user: mockUsers[getRandomInt(0, 2)],
  replies: mockReplyComments.slice(0, getRandomInt(0, mockReplyComments.length - 1))
}));

type participantsMock = {
  participantId: string;
  participantName: string;
  participantRole: string;
};

type messageMockProps = {
  conversationId: string;
  messageId: string;
  messageBody: string;
  messageSenderId: string;
  participants: participantsMock[];
};

function messageMock(props: messageMockProps): ChatMessageItemProps {
  return {
    conversationId: props.conversationId,
    message: {
      id: props.messageId,
      body: props.messageBody,
      createdAt: new Date(),
      senderId: props.messageSenderId,
      contentType: 'text',
    },
    participants: props.participants.map((participant) => ({
      id: participant.participantId,
      name: participant.participantName,
      role: participant.participantRole,
      email: 'test@test.com',
      address: '123 Main St',
      avatarUrl: '/static/images/avatars/avatar_1.jpg',
      phoneNumber: '+5551123456789',
      lastActivity: new Date(),
      status: 'online',
    })),
  };
}

export function getChatMessagesList(conversationId: string) {
  const admin = `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`;
  const customer = `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`;
  const installer = `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`;

  const chatMessagesList: ChatMessageItemProps[] = [
    messageMock({
      conversationId: '1',
      messageId: 'm1',
      messageBody: 'Esta é uma mensagem de teste.',
      messageSenderId: '2',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '1',
      messageId: 'm2',
      messageBody: 'Esta também é uma mensagem de teste.',
      messageSenderId: '2',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '1',
      messageId: 'm3',
      messageBody: 'Olá! Como posso te ajudar?',
      messageSenderId: '1',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '1',
      messageId: 'm4',
      messageBody: 'Tens alguma dúvida?',
      messageSenderId: '1',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '1',
      messageId: 'm5',
      messageBody: 'O instalador não compareceu.',
      messageSenderId: '2',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '1',
      messageId: 'm6',
      messageBody: 'Como prosseguir?',
      messageSenderId: '2',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),

    //--------------------------------------------

    messageMock({
      conversationId: '2',
      messageId: 'm1',
      messageBody: 'Esta é uma mensagem de teste.',
      messageSenderId: '3',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '3', participantName: installer, participantRole: 'Instalador' },
      ],
    }),
    messageMock({
      conversationId: '2',
      messageId: 'm2',
      messageBody: 'Olá! Como posso te ajudar?',
      messageSenderId: '1',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '2',
      messageId: 'm3',
      messageBody: 'O cliente não me atendeu.',
      messageSenderId: '3',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '3', participantName: installer, participantRole: 'Instalador' },
      ],
    }),

    //--------------------------------------------

    messageMock({
      conversationId: '3',
      messageId: 'm1',
      messageBody: 'Esta é uma mensagem de teste.',
      messageSenderId: '2',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
        { participantId: '3', participantName: installer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '3',
      messageId: 'm1',
      messageBody: 'É sério.',
      messageSenderId: '2',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
        { participantId: '3', participantName: installer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '3',
      messageId: 'm2',
      messageBody: 'Esta também é uma mensagem de teste.',
      messageSenderId: '3',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
        { participantId: '3', participantName: installer, participantRole: 'Cliente' },
      ],
    }),
    messageMock({
      conversationId: '3',
      messageId: 'm2',
      messageBody: 'Olá! Como posso ajudar vocês?',
      messageSenderId: '1',
      participants: [
        { participantId: '1', participantName: admin, participantRole: 'Admin' },
        { participantId: '2', participantName: customer, participantRole: 'Cliente' },
        { participantId: '3', participantName: installer, participantRole: 'Cliente' },
      ],
    }),
  ];

  return chatMessagesList.filter((message) => message.conversationId === conversationId);
}

export function getItemOptions() {
  const itemTypeOptions = [
    1,
    2,
    3
  ]

  const brandOptions = [
    'Samsung',
    'LG',
    'Philco',
    'Elgin',
    'Consul',
  ];

  const modelOptions = [
    'Split',
    'Split Inverter'
  ];

  const powerOptions = [
    9000,
    12000,
  ]

  const serviceTypeOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ]

  return { itemTypeOptions, brandOptions, modelOptions, powerOptions, serviceTypeOptions }
}
