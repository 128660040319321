import { Either } from 'src/utils/either';

import { ApiError } from 'src/http/errors/api-error';
import { httpResponseHandler } from 'src/http/http-response-handler';
import {
  _schedules,
  _activities,
  _mockComments,
  _orderStatuses,
  getItemOptions,
  getChatMessagesList,
  getOrderDetailsDataMock,
} from 'src/_mock/_orders-details';

import { ChatMessageItemProps } from 'src/types/order-details/chat';

import { HttpTypes } from '../../http';

export class OrderDetailsRepository {
  constructor(private api: HttpTypes.HttpClient) {}

  async getOrderDetailsData(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/order/details-data`,
      params: [{ key: 'orderId', value: orderId }],
    };

    const initialValues = getOrderDetailsDataMock();

    const options: HttpTypes.RequestOptions = {
      data: initialValues,
      status: 200,
      delay: 2000,
    };

    const response = await this.api.fakeRequest(request, options);

    return httpResponseHandler(response);
  }

  async getItemOptions(order_id?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/order/details-item-options`,
      params: [{ key: 'order_id', value: order_id }],
    };

    const initialValues = getItemOptions();

    const options: HttpTypes.RequestOptions = {
      data: initialValues,
      status: 200,
      delay: 500,
    };

    const response = await this.api.fakeRequest(request, options);

    return httpResponseHandler(response);
  }

  async getSchedules(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/order/schedules/${orderId}`,
    };
    const options: HttpTypes.RequestOptions = {
      data: _schedules,
      status: 200,
      delay: 2000,
    };
    const response = await this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async getActivitiesData(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/order/activities/${orderId}`,
    };
    const options: HttpTypes.RequestOptions = {
      data: _activities,
      status: 200,
      delay: 2000,
    };
    const response = await this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async getHistoryData(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/order/history/${orderId}`,
    };
    const options: HttpTypes.RequestOptions = {
      data: _orderStatuses,
      status: 200,
      delay: 2000,
    };
    const response = await this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async getChatMessagesList(conversationId: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/chat-messages-list`,
      params: [{ key: 'conversationId', value: conversationId }],
    };

    const initialValues = getChatMessagesList(conversationId);

    const options: HttpTypes.RequestOptions = {
      data: initialValues,
      status: 200,
      delay: 2000,
    };

    const response = await this.api.fakeRequest(request, options);

    return httpResponseHandler(response);
  }

  async getComments(orderId: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `/order/comments/${orderId}`,
    };
    const options: HttpTypes.RequestOptions = {
      data: _mockComments,
      status: 200,
      delay: 2000,
    };

    const response = await this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async sendOrdersData(
    crud_data: object,
    order_items: object[]
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: `/order/details-data`,
      body: { crud_data, order_items },
    };
    const options: HttpTypes.RequestOptions = {
      data: {},
      status: 200,
      delay: 1000,
    };
    const response = await this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async createComment(orderId: string, comment: any): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `/order/comments/${orderId}`,
      body: { comment },
    };
    // simulando erro
    // const options: HttpTypes.RequestOptions = {
    //   data: {
    //     message: 'Erro ao criar comentário',
    //   },
    //   status: 400,
    //   delay: 2000,
    // };
    const options: HttpTypes.RequestOptions = {
      data: {
        value: 'Anotação criada com sucesso',
      },
      status: 200,
      delay: 2000,
    };

    const response = this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async setOrderStatus(
    status: string,
    date: Date,
    reason?: string
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: `/order/details-data`,
      body: { status, date, reason },
    };
    const options: HttpTypes.RequestOptions = {
      data: {},
      status: 200,
      delay: 1000,
    };
    const response = await this.api.fakeRequest(request, options);

    return httpResponseHandler(response);
  }

  async postChatMessage(message: ChatMessageItemProps): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `/chat-messages`,
      body: {
        message,
      },
    };

    const options: HttpTypes.RequestOptions = {
      data: {},
      status: 200,
      delay: 2000,
    };

    const response = await this.api.fakeRequest(request, options);

    return httpResponseHandler(response);
  }

  async getRatingData(orderId: number): Promise<Either<ApiError, any | undefined>>{
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-requisicao/pedido/${orderId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }
}
