const ROOTS = {
  AUTH: '/auth',
  KANBAN: '/kanban',
  ORDERS: '/orders',
  INSTALLERS: '/installers',
  CLIENTS: '/clients',
  CYCLES: '/cycles',
  SETTINGS: '/settings',
};

export const paths = {
  root: '/',
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
    refreshTokens: '', // TODO: SEMPRE PREENCHER EM PROJETOS NOVOS
  },

  // PLOP.JS COMMENT BELOW FOR SCRIPTS, PLEASE DON'T REMOVE
  // PAGES
  kanban: {
    root: ROOTS.KANBAN,
  },
  orderDetails:{
    root: '/orderDetails/:id',
  },

  portal:{
    root: '/portal',
  },
  orders: {
    root: ROOTS.ORDERS,
    details: (id: string) => `${ROOTS.ORDERS}/details/${id}`,
  },

  installers: {
    root: ROOTS.INSTALLERS,
    details: (id: string) => `${ROOTS.INSTALLERS}/details/${id}`,
  },

  clients: {
    root: ROOTS.CLIENTS,
    details: (id: string) => `${ROOTS.CLIENTS}/details/${id}`,
  },

  cycles: {
    root: ROOTS.CYCLES,
  },

  settings: {
    root: ROOTS.SETTINGS,
  },

  error: {
    notFound: '/404',
    forbidden: '/403',
    serverError: '/500',
  },
};
