import { create } from 'zustand';

import { AxiosHttpClient } from 'src/http';
import { API_URL } from 'src/config-global';
import { ViaCepRepository } from 'src/repositories/via-cep/via-cep.repository';
import { OrderDetailsRepository } from 'src/repositories/orders/order-details.repository';

import { Comment, NewComment } from 'src/types/comment';
import { Status } from 'src/types/order-details/status';
import { Activity } from 'src/types/order-details/activity';
import { Schedule } from 'src/types/order-details/schedule';
import { OrderItem } from 'src/types/order-details/order-item';
import { ChatMessageItemProps } from 'src/types/order-details/chat';

type States = {
  isLoading: boolean;
  isSendingMessage: boolean;
  crudData: {
    name: string,
    cpfCnpj: string,
    email: string,
    phone: string,
    billingCep: string,
    billingAddress: string,
    billingCity: string,
    billingState: string,
    billingDistrict: string,
    billingNumber: number,
    billingComplement: string,
    installationCep: string,
    installationAddress: string,
    installationCity: string,
    installationState: string,
    installationDistrict: string,
    installationNumber: number,
    installationComplement: string,
    service_date?: string;
    service_shift?: string;
    order_date?: string;
    order_time?: string;
    total_value?: number;
    installer_value?: number;
    agyx_value?: number;
  }
  orderStatus: {
    status: string,
    date: Date,
    reason?: string,
  },
  stateOptions: string[]
  detailedOrderId?: number;
  orderItems: OrderItem[],
  messages: ChatMessageItemProps[];
  comments: Comment[];
  quickViewDrawerState: boolean;
  schedules: Schedule[];
  activities: Activity[];
  orderStatuses: Status[];
  isEqualToInstallation: boolean;
  itemTypeOptions: number[];
  brandOptions: string[];
  modelOptions: string[];
  powerOptions: number[];
  serviceTypeOptions: number[];
  defaultTransferPercent: number;
  currentChatId: string;
};

type Actions = {
  getOrderDetailsData: (id: string) => Promise<void>;
  setDetailedOrderId: (value: number | undefined) => void;
  setQuickViewDrawerState: (value: boolean) => void;
  getActivitiesData: (id: string) => Promise<void>;
  getSchedules: (id: string) => Promise<void>;
  getLocalByCep: (cep?: string) => Promise<{
    localidade: string;
    uf: string;
    logradouro: string;
    bairro: string;
  }>;
  getChatMessagesList: (conversationId: string) => Promise<void>;
  getComments: (orderId: string) => Promise<void>;
  createComment: (orderId: string, comment: NewComment) => Promise<string>;
  getItemsOptions: () => Promise<void>;
  addOrderItems: (data: OrderItem) => Promise<void>;
  editOrderItem: (data: OrderItem, id: number) => Promise<void>
  deleteOrderItem: (id: number) => Promise<void>;
  sendOrderData: (data: object) => Promise<void>;
  setOrderStatus: (status: string, date: Date, reason?: string) => Promise<void>
  setCurrentChatId: (value: string) => void;
  postChatMessage: (message: ChatMessageItemProps) => Promise<void>;
  getRatingData: (orderId: number) => any;
};

type OrderDetails = Actions & States;

const initialValues = {
  isLoading: false,
  isSendingMessage: false,
  crudData: {
    name: '',
    cpfCnpj: '',
    email: '',
    tel: 0,
    phone: '',
    billingCep: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingDistrict: '',
    billingNumber: 0,
    billingComplement: '',
    installationCep: '',
    installationAddress: '',
    installationCity: '',
    installationState: '',
    installationDistrict: '',
    installationNumber: 0,
    installationComplement: '',
    service_date: '',
    service_shift: '',
    order_date: '',
    order_time: '',
    total_value: 0,
    installer_value: 0,
    agyx_value: 0,
  },
  detailedOrderId: undefined,
  orderItems: [
    {
      id: 0,
      brand: '',
      model: '',
      power: 0,
      serviceType: 0,
      itemType: 0,
      unitValue: 0,
      totalValue: 0,
      transferValue: 0,
      transferAgyx: 0,
      transferPercent: 0,
    },
  ],
  orderStatus: {
    status: '',
    date: new Date,
  },
  stateOptions: [],
  quickViewDrawerState: false,
  schedules: [],
  activities: [],
  orderStatuses: [],
  isEqualToInstallation: true,
  messages: [],
  comments: [],
  itemTypeOptions: [],
  brandOptions: [],
  modelOptions: [],
  powerOptions: [],
  serviceTypeOptions: [],
  defaultTransferPercent: 0,
  currentChatId: '1',
};

const api = new AxiosHttpClient(API_URL);
const cepApi = new AxiosHttpClient('http://viacep.com.br');

const repository = new OrderDetailsRepository(api);
const cepRepository = new ViaCepRepository(cepApi);

export const useOrderDetails = create<OrderDetails>()((set: any, get: any) => ({
      ...initialValues,

      getOrderDetailsData: async (id) => {
        set({ isLoading: true });
        const response = await repository.getOrderDetailsData(id);
        if (response.isLeft()) {
          set({ isLoading: false });
          return;
        }
        set({
          isLoading: false,
          crudData: {
            name: response.value.name,
            cpfCnpj: response.value.cpf_cnpj,
            email: response.value.email,
            phone: response.value.phone,
            billingCep: response.value.billing_cep,
            billingAddress: response.value.billing_address,
            billingCity: response.value.billing_city,
            billingState: response.value.billing_state,
            billingDistrict: response.value.billing_district,
            billingNumber: response.value.billing_number,
            billingComplement: response.value.billing_complement,
            installationCep: response.value.installation_cep,
            installationAddress: response.value.installation_address,
            installationCity: response.value.installation_city,
            installationState: response.value.installation_state,
            installationDistrict: response.value.installation_district,
            installationNumber: response.value.installation_number,
            installationComplement: response.value.installation_complement,
            service_date: response.value.service_date,
            service_shift: response.value.service_shift,
            order_date: response.value.order_date,
            order_time: response.value.order_time,
            total_value: response.value.total_value,
            installer_value: response.value.installer_value,
            agyx_value: response.value.agyx_value,
          },
          orderItems: response.value.order_items.map((item: any) => ({
            id: item.id,
            model: item.modelo,
            brand: item.marca,
            power: item.potencia,
            serviceType: item.tipo_servico_id,
            itemType: item.tipo_item_id,
            unitValue: item.valor_unitario,
            totalValue: item.valor_liquido,
            transferValue: item.valor_repasse,
            transferAgyx: item.valor_repasse_agyx,
            transferPercent: item.porcentagem_repasse,
          })),
          defaultTransferPercent: response.value.defaultTransferPercent,
          isEqualToInstallation: response.value.isEqualToInstallation,
          stateOptions: response.value.stateOptions,
          orderStatus: {
            status: response.value.orderStatus.status,
            date: response.value.orderStatus.date,
            reason: response.value.orderStatus.reason
          },
        });
      },

      getItemsOptions: async () => {
        set({ isLoading: true })
        const response = await repository.getItemOptions()
        if (response.isLeft()) {
          set({ isLoading: false })
          return;
        }
        set({
          isLoading: false,
          itemTypeOptions: response.value.itemTypeOptions,
          brandOptions: response.value.brandOptions,
          modelOptions: response.value.modelOptions,
          powerOptions: response.value.powerOptions,
          serviceTypeOptions: response.value.serviceTypeOptions,
        })
      },

      addOrderItems: async (data) => {
        set({ isLoading: true })
        set((state: any) => ({
          isLoading: false,
          orderItems: [...state.orderItems, data]
        }))
      },

      editOrderItem: async (data, id) => {
        const editedItemIndex = get().orderItems.findIndex((item: any) => item.id === id);

        if (editedItemIndex !== -1) {
          set((state: any) => ({
            orderItems: [
              ...state.orderItems.slice(0, editedItemIndex),
              data,
              ...state.orderItems.slice(editedItemIndex + 1)
            ]
          }));
        }
      },

      deleteOrderItem: async (id) => {
        set((state: any) => ({ orderItems: [...state.orderItems.filter((item: any) => item.id !== id)] }))
      },

      sendOrderData: async (data) => {
        set({ isLoading: true })
        await repository.sendOrdersData(data, get().orderItems);
        set({ isLoading: false })
      },

      setOrderStatus: async (status, date, reason) => {
        set({ isLoading: true })
        await repository.setOrderStatus(status, date, reason)
        set({ isLoading: false, orderStatus: { status, date, reason } })
      },

      setDetailedOrderId(value: number | undefined) {
        set({ detailedOrderId: value });
      },

      setQuickViewDrawerState(value: boolean) {
        set({ quickViewDrawerState: value });
      },
      getActivitiesData: async (orderId) => {
        const [activitiesResponse, statusesResponse] = await Promise.all([
          repository.getActivitiesData(orderId),
          repository.getHistoryData(orderId),
        ]);
        if (activitiesResponse.isLeft() || statusesResponse.isLeft()) {
          return;
        }
        set({
          activities: activitiesResponse.value,
          orderStatuses: statusesResponse.value,
        });
      },

      getSchedules: async (orderId) => {
        const response = await repository.getSchedules(orderId);
        if (response.isLeft()) {
          return;
        }
        set({ schedules: response.value });
      },

      getLocalByCep: async (cep) => {
        try {
          const response = await cepRepository.getLocalByCep(cep);
          if (response?.value) {
            const { localidade, uf } = response.value;
            if (localidade && uf) {
              return response.value;
            }
          }
          return null;
        } catch (error) {
          throw error;
        }
      },

      getChatMessagesList: async (conversationId) => {
        const response = await repository.getChatMessagesList(conversationId);
        if (response.isLeft()) {
          return;
        }
        set({ messages: response.value });
      },

      getComments: async (orderId) => {
        const response = await repository.getComments(orderId);
        if (response.isLeft()) {
          return;
        }
        set({ comments: response.value });
      },

      createComment: async (orderId, comment) => {
        const response = await repository.createComment(orderId, comment);
        if (response.isLeft()) {
          throw new Error(response.value.message);
        }
        return response.value.message;
      },

      setCurrentChatId: (value: string) => {
        set({ currentChatId: value });
      },

      postChatMessage: async (message) => {
        set({ isSendingMessage: true });
        await repository.postChatMessage(message);
        set({ messages: [...get().messages, message], isSendingMessage: false });
      },
      
      getRatingData: async (orderId) => {
        set({ isLoading: true });
        const response = await repository.getRatingData(orderId);
        if (response.isLeft()) {
          set({ isLoading: false });
          throw new Error(response.value.message);
        }
        set({ isLoading: false});
        return Promise.resolve(response.value[0]);
      }
    }),
);
