import { useLocation } from "react-router";
import { useState, useEffect } from "react";

import { paths } from "src/routes/paths";

export const useTabs = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case paths.orders.root:
        setValue(0);
        break;
      case '/':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
    const TabsHook: any = {
      value,
      handleChange
    }
  
    return TabsHook;
  
  };
  