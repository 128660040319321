import moment from 'moment';

import { GridColDef } from '@mui/x-data-grid';

import { Sort } from 'src/types/orders/sort';

export const tabs = [
  { label: 'general', count: 0 },
  { label: 'notAttended', count: 0 },
  { label: 'inProgress', count: 0 },
  { label: 'waiting', count: 0 },
  { label: 'waitingMatch', count: 0 },
  { label: 'noMatch', count: 0 },
  { label: 'scheduled', count: 0 },
  { label: 'latePending', count: 0 },
  { label: 'waitingNF', count: 0 },
  { label: 'completed', count: 0},
  { label: 'cycle', count: 0 },
  { label: 'paidFinished', count: 0 },
  { label: 'warranty', count: 0 },
  { label: 'canceled', count: 0 },
];

export const origins = ['Frigelar', 'Samsung', 'Mercado Livre', 'Loja Parceira'];
export const popularNames = [
  'Miguel',
  'Arthur',
  'Heitor',
  'Helena',
  'Alice',
  'Laura',
  'Gabriel',
  'Bernardo',
  'Samuel',
  'Sophia',
  'Maria',
  'Carlos',
  'João',
  'Ana',
  'Lucas',
  'Mariana',
  'Rafael',
  'Fernanda',
  'Guilherme',
  'Juliana',
  'Felipe',
  'Roberto',
  'Bruno',
  'Beatriz',
  'Patrícia',
  'Rodrigo',
  'Eduardo',
  'Matheus',
  'Camila',
  'Ricardo',
];
export const popularSurnames = [
  'Silva',
  'Santos',
  'Oliveira',
  'Pereira',
  'Souza',
  'Rodrigues',
  'Ferreira',
  'Almeida',
  'Costa',
  'Gomes',
  'Martins',
];
export const cities = [
  'Porto Alegre',
  'Canoas',
  'Novo Hamburgo',
  'São Leopoldo',
  'Gravataí',
  'Viamão',
  'Alvorada',
  'Guaíba',
  'Sapucaia do Sul',
  'Esteio',
];
export const states = ['RS'];
export const statuses = [
  'Aguardando peças',
  'Aguardando MT',
  'Agendado/Preventiva',
  'Aguardando NOC',
  'Concluído',
];

export const allServices = [...Array(10)].map((_, index) => ({
  id: index + 1,
  produto: 'Split Inverter',
  marca: 'Samsung',
  modelo: 'RT6000K',
  potencia: '9000',
  tipo_servico_id: getRandomInt(1, 12),
  tipo_item_id: 1,
  valor_unitario: 1000.00,
  valor_desconto: 0,
  valor_liquido: 1000.00,
  valor_repasse: 740.00,
  valor_repasse_agyx: 360.00,
}));

export function getRandomServices(services: any[]) {
  const numServices = Math.floor(Math.random() * 4) + 1;
  const shuffled = services.sort(() => 0.5 - Math.random());
  const selectedServices = shuffled.slice(0, numServices);

  return selectedServices.sort((a, b) => a.id - b.id);
}

export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomTime(): Date {
  const now = new Date();
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(now.getDate() - 1);

  const randomTime = new Date(
    twoWeeksAgo.getTime() + Math.random() * (now.getTime() - twoWeeksAgo.getTime())
  );

  return randomTime;
}


export function getRandomItem<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}


export function getRandomCode(): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';

  for (let i = 0; i < 10; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
}

export function getRandomCPF(): string {
  const randomDigit = () => Math.floor(Math.random() * 10);

  const digits = Array.from({ length: 9 }, randomDigit);

  let sum = digits.reduce((acc, digit, index) => acc + digit * (10 - index), 0);
  let firstVerifier = sum % 11;
  if (firstVerifier > 9) firstVerifier = 0;

  digits.push(firstVerifier);

  sum = digits.reduce((acc, digit, index) => acc + digit * (11 - index), 0);
  let secondVerifier = sum % 11;
  if (secondVerifier > 9) secondVerifier = 0;

  digits.push(secondVerifier);

  return `${digits.slice(0, 3).join('')}.${digits.slice(3, 6).join('')}.${digits.slice(6, 9).join('')}-${digits.slice(9).join('')}`;
}

export function getRandomFloat(): number {
  return parseFloat((Math.random() * (5000 - 500) + 500).toFixed(2));
}

export function generateMockData(sort: Sort, sortBy: string, page: number, rowsPerPage: number) {
  const data = Array.from({ length: rowsPerPage }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    date: getRandomTime(),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    status: getRandomItem(statuses),
    step: moment().subtract(getRandomInt(1, 5), 'days'),
    total: moment().subtract(getRandomInt(5, 10), 'days'),
  }));

  const compare = (a: { [key: string]: any }, b: { [key: string]: any }) => {
    if (a[sortBy] < b[sortBy]) {
      return sort === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sort === 'asc' ? 1 : -1;
    }
    return 0;
  };

  data.sort(compare);

  return { totalRows: getRandomInt(rowsPerPage + 1, rowsPerPage * 2), data };
}

export function getNotAttendedMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    date: getRandomTime(),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    step: moment().subtract(getRandomInt(1, 5), 'days'),
    total: moment().subtract(getRandomInt(5, 10), 'days'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getInProgressMock(sort: Sort, sortBy: string, page: number, rowsPerPage: number) {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    date: getRandomTime(),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    forecast: getRandomTime(),
    shift: getRandomItem(['Manhã', 'Tarde']),
    step: moment().subtract(getRandomInt(1, 5), 'days'),
    total: moment().subtract(getRandomInt(5, 10), 'days'),
  }));

  const compare = (a: { [key: string]: any }, b: { [key: string]: any }) => {
    if (a[sortBy] < b[sortBy]) {
      return sort === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sort === 'asc' ? 1 : -1;
    }
    return 0;
  };

  data.sort(compare);

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getOnHoldMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    date: getRandomTime(),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    status: moment().add(getRandomInt(0, 10), 'days'),
    step: moment().subtract(getRandomInt(1, 30), 'hours'),
    total: moment().subtract(getRandomInt(1, 100), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getWaitingMatchMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { 
      name: getRandomItem(origins), 
      code: getRandomCode() 
    },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    date: moment().subtract(getRandomInt(1, 30), 'days').toDate(),
    installer: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    },
    responsible: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    },
    step: moment().subtract(getRandomInt(1, 30), 'hours').toDate(),
    total: moment().subtract(getRandomInt(1, 100), 'hours').toDate(),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getNoMatchMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { 
      name: getRandomItem(origins), 
      code: getRandomCode() 
    },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    scheduled_date: getRandomTime(),
    scheduled_turn: getRandomItem(['Manhã', 'Tarde']),
    responsible: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    },
    step: moment().subtract(getRandomInt(1, 30), 'hours'),
    total: moment().subtract(getRandomInt(1, 100), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getScheduledMock(sort: Sort, sortBy: string, page: number, rowsPerPage: number) {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { 
      name: getRandomItem(origins), 
      code: getRandomCode() 
    },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    forecast: getRandomTime(),
    shift: getRandomItem(['Manhã', 'Tarde']),
    installer: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    },
    responsible: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    },
    total: moment().subtract(getRandomInt(1, 100), 'hours'),
  }));

  const compare = (a: { [key: string]: any }, b: { [key: string]: any }) => {
    if (a[sortBy] < b[sortBy]) {
      return sort === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sort === 'asc' ? 1 : -1;
    }
    return 0;
  };

  data.sort(compare);

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getLatePendingMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    scheduled_date: getRandomTime(),
    scheduled_turn: getRandomItem(['Manhã', 'Tarde']),
    installer: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    step: moment().subtract(getRandomInt(1, 20), 'hours'),
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getWaitingInvoiceMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { 
      name: getRandomItem(origins), 
      code: getRandomCode() 
    },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    scheduled_date: getRandomTime(),
    scheduled_turn: getRandomItem(['Manhã', 'Tarde']),
    installer: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, 
    },
    responsible: { 
      id: getRandomInt(100, 999),
      name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, 
    },
    step: moment().subtract(getRandomInt(1, 20), 'hours'),
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getCompletedMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    installer: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    step: moment().subtract(getRandomInt(1, 20), 'hours'),
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getCycleMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    installer: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    step: moment().subtract(getRandomInt(1, 20), 'hours'),
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getPaidFinishedMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    installer: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    step: moment().subtract(getRandomInt(1, 20), 'hours'),
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getWarrantyMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    installer: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    step: moment().subtract(getRandomInt(1, 20), 'hours'),
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getCanceledMock() {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    order: getRandomInt(1500, 17725),
    origin: { name: getRandomItem(origins), code: getRandomCode() },
    client: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`,
    cpf: getRandomCPF(),
    services: getRandomServices(allServices),
    price: getRandomFloat(),
    city: getRandomItem(cities),
    state: getRandomItem(states),
    reason: getRandomItem(['Cliente desistiu', 'Falta de peças', 'Falta de mão de obra', 'Falta de tempo', 'Ficou sem água', 'Ficou sem luz']),
    responsible: { name: `${getRandomItem(popularNames)} ${getRandomItem(popularSurnames)}`, id: getRandomInt(100, 999) },
    total: moment().subtract(getRandomInt(5, 20), 'hours'),
  }));

  return { totalRows: getRandomInt(10 + 1, 10 * 2), data }
}

export function getTabsCount() {
  return Array.from({ length: tabs.length }, (_, index) => ({
    label: tabs[index].label,
    count: getRandomInt(0, 100),
  }));
}

export const columns: GridColDef[] = [
  { field: 'order', headerName: 'Pedido', width: 125 },
  { field: 'date', headerName: 'Data', width: 125 },
  { field: 'origin', headerName: 'Origem', width: 125, renderCell: (params) => params.value },
  { field: 'client', headerName: 'Cliente', width: 125 },
  { field: 'service', headerName: 'Serviços', width: 125 },
  { field: 'local', headerName: 'Local', width: 160 },
  { field: 'responsible', headerName: 'Responsável', width: 125 },
  { field: 'status', headerName: 'Status', width: 160 },
  { field: 'step', headerName: 'Etapa', width: 125, renderCell: (params) => params.value },
  { field: 'total', headerName: 'Total', width: 125, renderCell: (params) => params.value },
];

