import React from 'react';

import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Menu,
  Stack,
  Badge,
  AppBar,
  Toolbar,
  MenuItem,
  IconButton,
  Typography,
  badgeClasses,
} from '@mui/material';

import Logo from 'src/components/logo';

import HeaderTabs from './tabs/tabs';
import { HEADER } from '../config-layout';
import { useHeader } from './header.hook';

export interface HeaderProps {}

export default function Header(Props: HeaderProps) {
  const { theme, handleMenu, anchorEl, handleClose } = useHeader();

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          gap: 3,
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          backgroundColor: theme.palette.primary.darker,
        }}
      >
        <Stack
          mx={15}
          direction="row"
          alignItems="center"
          height={1}
          width="100%"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" sx={{ height: 1, width: '100%' }}>
            <Box sx={{ mr: 10, display: 'flex', alignItems: 'center' }}>
              <Badge
                sx={{
                  [`& .${badgeClasses.badge}`]: {
                    top: 8,
                    right: -16,
                  },
                }}
              >
                <Logo height={32} width={80.33} data-testid="logo" />
              </Badge>
            </Box>
            <Stack 
              height="100%"
              sx={{ ml: 2 }}
            >  
              <HeaderTabs />
            </Stack>
          </Box>
          <Stack>
            <IconButton
              size="small"
              onClick={handleMenu}
              data-testid="account-icon-button"
              sx={{ color: 'common.white' }}
            >
              <AccountCircle sx={{ width: '32px', height: '32px' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              data-testid="account-menu"
            >
              <MenuItem onClick={handleClose}>
                <Typography variant="button">Perfil</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Typography variant="button">Minha Conta</Typography>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
