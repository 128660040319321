import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from 'src/config-global';
// import { AuthGuard } from '../../auth/guard';

import { SplashScreen } from 'src/components/loading-screen';

import { HeaderLayout, DefaultLayout, OrderDetailsLayout } from '../../layouts/index';

// import { authRoutes } from './auth';

import { paths } from '../paths';
import { authRoutes } from './auth';
import { errorRoutes } from './error';

// PLOP.JS COMMENT BELOW FOR SCRIPTS, PLEASE DON'T REMOVE
// PAGE_IMPORTS
const OrderDetails = lazy(() => import("../../modules/orders/order-details/order-details"));
const Kanban = lazy(() => import("../../modules/orders/kanban/kanban"));
const Orders = lazy(() => import('../../modules/orders/table/orders'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<SplashScreen />}>
          <HeaderLayout>
            <Navigate to={PATH_AFTER_LOGIN} replace />
          </HeaderLayout>
        </Suspense>
      ),
    },
    ...authRoutes,

    { path: '*', element: <Navigate to="/404" replace /> },

    ...errorRoutes,

    // PLOP.JS COMMENT FOR BELOW SCRIPTS, PLEASE DON'T REMOVE
    // RELATION_PATH_PAGES

    {
      path: paths.orders.details(':id'), element:
        // <AuthGuard>
        <Suspense fallback={<SplashScreen/>}>
          <OrderDetailsLayout>
            <OrderDetails />
          </OrderDetailsLayout>
        </Suspense>
      // </AuthGuard>
    },
    {
      path: paths.kanban.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <DefaultLayout>
            <Kanban />
          </DefaultLayout>
        </Suspense>
      ),
    },
    {
      path: paths.orders.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <DefaultLayout>
            <Orders />
          </DefaultLayout>
        </Suspense>
      ),
    },
  ]);
}
