import { useAuthStore } from './auth.store';

export const useAuth = () => {
  const { accessToken, refreshToken, setIsAuthenticated } = useAuthStore();

  const initializeAuth = () => {
    if (accessToken && refreshToken) {
      // TODO: Rota pra pegar os dados do usuário e testar se o token é válido
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  const useAuthHook: any = {
    initializeAuth,
  };

  return useAuthHook;
};
