import { useState } from 'react';

import { useTheme } from '@mui/material';

export const useHeader = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HeaderHook: any = {
    handleMenu,
    handleClose,
    theme,
    anchorEl,
  };

  return HeaderHook;
};
